import React, { useEffect, useState } from "react";
import { Axios } from "../config/axios";
import { Link } from "react-router-dom";
import { GET_IMAGES_RAMO } from "../constants";
import { Container, Row, Col, Card, Image, Button, Nav } from "react-bootstrap";
import { useAuthDispatch, useAuthState } from "../context/auth";
import cumple from "../assets/icons/comprobar.svg";
import nocumple from "../assets/icons/cerrar.svg";

import moment from "moment";
import { Pagination } from "@material-ui/lab";

export const Ramo = ({ history }) => {
  const [images, setImages] = useState([]);
  const [backup, setBackup] = useState([]);
  const [route, setRoute] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [imagesPerPage, setImagesPerPage] = useState(20);

  const authDispatch = useAuthDispatch();
  useEffect(() => {
    getImages();
  }, []);

  const getImages = () => {
    Axios.get(GET_IMAGES_RAMO)
      .then((res) => {
        console.log(res.data.data);
        setImages(res.data.data);
        setBackup(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilter = (route, type) => {
    setRoute(route);
    setCurrentPage(1);
    if (type == null) {
      getImages();
    } else {
      const newImages = backup.filter((i) => i.verdict == type);
      setImages(newImages);
    }
  };

  const end = currentPage * imagesPerPage;
  const start = end - imagesPerPage;
  const currentImages = images.slice(start, end);

  const logout = () => {
    authDispatch({ type: "LOGOUT" });
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Container className="p-5">
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link
              className={
                route == 0
                  ? "bg-primary text-white h4"
                  : "bg-white text-primary h4"
              }
              onClick={() => handleFilter(0, null)}
            >
              Imagenes
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link
              className={
                route == 1
                  ? "bg-primary text-white h4"
                  : "bg-white text-primary h4"
              }
              onClick={() => handleFilter(1, true)}
            >
              Cumplen
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                route == 2
                  ? "bg-primary text-white h4"
                  : "bg-white text-primary h4"
              }
              onClick={() => handleFilter(2, false)}
            >
              No cumplen
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
        <Row>
          {currentImages.map((img, i) => (
            <Col key={i} sm={4} className="p-2">
              <Card className="p-3">
                <Link to={`/${img.id}`}>
                  <Image src={img.url} fluid />
                </Link>
                <Card.Body className="d-flex flex-column justify-content-center">
                  <Row>
                    <p className="h6 font-weight-bold">
                      Fecha de captura:{" "}
                      <span className="ml-2 font-weight-normal">
                        {moment(img.date).format("D/MM/YYYY, h:mm a")}
                      </span>
                    </p>
                  </Row>
                  <Row>
                    <p className="h6 font-weight-bold">
                      Codigo del cliente:{" "}
                      <span className="ml-2 font-weight-normal">
                        {img.clientId}
                      </span>
                    </p>
                  </Row>
                  {/* <Row>
                    <p className="h5 font-weight-normal">
                      Nombre del cliente:{" "}
                      <span className="ml-2 font-weight-bold">
                        {img.clientName}
                      </span>
                    </p>
                  </Row> */}
                  <Row>
                    <p className="h6 font-weight-bold">
                      Codigo regional:{" "}
                      <span className="ml-2 font-weight-normal">
                        {img.wareHouse}
                      </span>
                    </p>
                  </Row>
                  <Row>
                    <p className="h6 font-weight-bold">
                      Codigo de la tienda:{" "}
                      <span className="ml-2 font-weight-normal">
                        {img.storeCode}
                      </span>
                    </p>
                  </Row>
                  <Row>
                    <p className="h6 font-weight-bold">
                      Nombre de la tienda:{" "}
                      <span className="ml-2 font-weight-normal">
                        {img.storeName}
                      </span>
                    </p>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  {/* <Button
                    variant="primary"
                    onClick={() => history.push(`/${img.id}`)}
                  >
                    Detalles
                  </Button>
                  <img
                    className="ml-2 mt-1"
                    src={img.verdict ? cumple : nocumple}
                    width="30"
                    height="30"
                  /> */}
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
        <div className="d-flex justify-content-center mt-5">
          <Pagination
            siblingCount={2}
            size="large"
            variant="outlined"
            color="primary"
            count={Math.ceil(images.length / imagesPerPage)}
            page={currentPage}
            onChange={handleChange}
          />
        </div>
      </Container>
    </div>
  );
};
