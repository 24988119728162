import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  makeStyles,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Container,
  Typography,
  IconButton,
  InputLabel,
  Collapse,
  Box,
} from "@material-ui/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

import clsx from "clsx";
import { Axios } from "../../../../config/axios";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import {
  DASHBOARD,
  DASHBOARD_DAY,
  GET_OBJETIVES_DETAILS_DAY,
  GET_OBJETIVES_REGIONS_DAY,
  GET_PLANTS,
  GET_REGION,
  GET_REGIONS,
  GET_REGIONS_DAY,
  GET_SEASONS,
} from "../../../../constants";
import AnimatedNumber from "animated-number-react";
import CountUp from "react-countup";
import { formatPercentaje } from "../../../../utils/percentaje";

import Select from "react-dropdown-select";
import { useAuthState } from "../../../../context/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 400,
  },
  fixedHeightMin: {
    height: 170,
  },
}));

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          style={{ color: "#2e1156", cursor: "pointer" }}
          component="th"
          scope="row"
        >
          {row.region}
        </TableCell>
        <TableCell>{row.objetivo}</TableCell>
        <TableCell>
          {new Intl.NumberFormat("en").format(row.objetivoAcumulado.toFixed(0))}
        </TableCell>
        <TableCell>{row.ejecutados}</TableCell>
        <TableCell>{formatPercentaje(row.cumplimiento) + "%"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography
                style={{ fontWeight: "normal" }}
                variant="h6"
                gutterBottom
                component="div"
              >
                Sedes
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Planta
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Objetivo
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Objetivo Acumulado
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Ejecutados
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Cumplimiento
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.detail &&
                    row.detail.map((d, i) => (
                      <TableRow key={i}>
                        <TableCell align="left">{d.nombrePlanta}</TableCell>
                        <TableCell align="left">
                          {new Intl.NumberFormat("en").format(d.objetivo)}
                        </TableCell>
                        <TableCell align="left">
                          {new Intl.NumberFormat("en").format(
                            d.objetivoAcumulado.toFixed(0)
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {new Intl.NumberFormat("en").format(d.ejecutados)}
                        </TableCell>

                        <TableCell>
                          {new Intl.NumberFormat("en").format(
                            d.cumplimiento && d.cumplimiento.toFixed(1)
                          ) + "%"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const ReportsRDay = () => {
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [charts, setCharts] = useState([]);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState(null);
  const [plants, setPlants] = useState([]);
  const [plant,setPlant] = useState(null)
  const [data, setData] = useState(null);

  const [objetivesDay, setObjetivesDay] = useState([]);

  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState("");

  const { user } = useAuthState();

  const [selected, setSelected] = useState(false);

  const salesForce = [
    {
      label: "BNA",
      value: "BNA",
    },
    {
      label: "FVI",
      value: "FVI",
    },
  ];

  const [force, setForce] = useState(salesForce[0].value);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixed = clsx(classes.paper, classes.fixedHeightMin);

  const formatValue = (value) => `${formatPercentaje(value)} %`;

  const getSeasons = () => {
    Axios.get(GET_SEASONS)
      .then((res) => {
        console.log(res.data.data);
        let season = res.data.data[0].id;
        setSeason(season);
        setSeasons(res.data.data.filter(temp => temp.active == 1));
        getObjetivesRegionsDay(season);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getObjetivesRegionsDay = (season) => {
    Axios.get(GET_OBJETIVES_REGIONS_DAY + season)
      .then((res) => {
        console.log(res.data);
        let data = res.data;
        Axios.get(GET_OBJETIVES_DETAILS_DAY + season)
          .then((res) => {
            console.log(res.data);
            const details = res.data;
            groupby(data, details);
            data = data.filter((x) => x.region == user.regional);
            console.log(data);
            setObjetivesDay(data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const groupby = (data, details) => {
    data.forEach((d) => {
      details.forEach((de) => {
        if (d.region == de.region) {
          let detail = d.detail == null ? [] : d.detail;
          detail.push(de);
          d.detail = detail;
        }
      });
    });
  };

  const getRegions = () => {
    Axios.get(GET_REGIONS_DAY)
      .then((res) => {
        console.log(res.data);
        setRegions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getData = (season,region, planta) => {
    let url = `${DASHBOARD_DAY}?season=${season}`;
    if (planta == null) {
      url += `&region=${region}`;
    } else {
      url += `&region=${region}&idPlanta=${planta}`;
    }
    console.log(url)
    Axios.get(`${url}`)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data.values);
        setCharts(res.data.data.charts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPlants = (region) => {
    Axios.get(`${GET_PLANTS}?region=${region}`)
      .then((res) => {
        console.log(res.data.data);
        setPlants(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSeasons();
    getPlants(user.regional);
  }, []);

  const handleRegion = (value) => {
    console.log(value);
    setPlants([]);
    setRegion(value);
    getPlants(value);
    getData(value, null);
  };

  const handlePlant = (value) => {
    setPlant(value)
    getData(season,user.regional, value);
  };

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: "2rem" }}>
        <Grid item xs={12} md={3} lg={3}>
          <h5>Region</h5>
          <InputLabel id="demo-simple-select-label">{user.regional}</InputLabel>
        </Grid>
        {/* <Grid item xs={12} md={3} lg={3}>
          <InputLabel id="demo-simple-select-label">
            Fuerza de ventas
          </InputLabel>
          <Select
            style={{
              marginTop: "1rem",
              borderRadius: "7px",
              height: "55px",
            }}
            color="#85C1E9"
            options={salesForce}
            labelField="label"
            valueField="value"
            onChange={(value) => {
              const force = value[0].value
              setForce(force);
              getObjetivesRegionsDay(season, force);
              getData(user.regional,null,force)
            }}
            values={[salesForce[0]]}
          />
        </Grid> */}
        <Grid item xs={12} md={3} lg={3}>
          <InputLabel id="demo-simple-select-label">Misiones</InputLabel>
          <Select
            style={{
              marginTop: "1rem",
              borderRadius: "7px",
              height: "55px",
            }}
            color="#85C1E9"
            options={seasons}
            labelField="name"
            valueField="id"
            values={[{ id: -1, name: "Seleccione una Misión" }]}
            onChange={(value) => {
              const selectedSeason = value[0];
              setSelected(true);
              setSeason(selectedSeason.id)
              getData(selectedSeason.id,user.regional,plant)
            }}
          />
        </Grid>
        {selected && (
          <Grid item xs={12} md={6} lg={6}>
            <InputLabel id="demo-simple-select-label">Plantas</InputLabel>
            <Select
              style={{
                marginTop: "1rem",
                borderRadius: "7px",
                height: "55px",
              }}
              color="#85C1E9"
              options={plants}
              labelField="nombrePlanta"
              valueField="codPlanta"
              onChange={(value) => handlePlant(value[0].codPlanta)}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} lg={3}>
          <Paper
            elevation={3}
            className={fixed}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#85C1E9", fontWeight: "bold" }}
            >
              <CountUp end={data && data.total} />
            </Typography>
            <Typography variant="h6" gutterBottom>
              Total Fotos
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Paper
            elevation={3}
            className={fixed}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#5BFF33", fontWeight: "bold" }}
            >
              <CountUp end={data && data.v} />
            </Typography>
            <Typography variant="h6" gutterBottom>
              Cumplen
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Paper
            elevation={3}
            className={fixed}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#FF4633", fontWeight: "bold" }}
            >
              <CountUp end={data && data.f} />
            </Typography>
            <Typography variant="h6" gutterBottom>
              No cumplen
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Paper
            elevation={3}
            className={fixed}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#FCA130", fontWeight: "bold" }}
            >
              <AnimatedNumber
                value={data && data.e}
                formatValue={formatValue}
                duration={1200}
              />
            </Typography>
            <Typography variant="h6" gutterBottom>
              Efectividad
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper} elevation={3}>
            <ResponsiveContainer>
              <BarChart
                width={800}
                height={300}
                data={charts}
                margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
              >
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="total" name="Fotos subidas" fill="#85C1E9" />

                <Bar dataKey="sumV" name="Cumplen" fill="#5BFF33" />

                <Bar dataKey="sumF" name="No cumplen" fill="#FF4633" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TableContainer style={{ maxHeight: 470 }}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell style={{ minWidth: 70 }}>Region</TableCell>
                  <TableCell style={{ minWidth: 140 }}>Objetivo</TableCell>
                  <TableCell style={{ minWidth: 140 }}>
                    Objetivo Acumulado{" "}
                  </TableCell>
                  <TableCell style={{ minWidth: 140 }}>Ejecutados </TableCell>
                  <TableCell style={{ minWidth: 140 }}>Cumplimiento </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objetivesDay.length > 0 &&
                  objetivesDay.map((row, i) => <Row key={i} row={row} />)}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
