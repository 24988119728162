import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Images } from "./components/Images";
import { Img } from "./components/Img";
import { AuthProvider } from "./context/auth";
import DynamicRoute from "./utils/DynamicRoute";

import Login from "./components/auth/Login";

import "toasted-notes/src/styles.css"; // optional styles
//import './App.css';
import "./styles/app.scss";

import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import { Ramo } from "./components/Ramo";
import Info from "./components/Info/Info";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import TablaRDV from "./components/TablaRDV";
import TablaRVDImage from "./components/TablaRVDImage";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/info" component={Info} />
          <Route exact path="/misiones/:vendorCode/:planta/:cliente_id" component={TablaRDV} />
          <Route exact path="/misiones/:cliente_id/:vendorcode/:planta/:seasonid/"component={TablaRVDImage} />
          <Route exact path="/images/ramo" component={Ramo} />
          {/**<DynamicRoute exact path="/" component={Images} authenticated /> **/}
          <DynamicRoute
            exact
            path="/dashboard"
            
            component={Dashboard}
            authenticated
          />
          <DynamicRoute path="/login" component={Login} guest />
          <DynamicRoute
            exact
            path="/:id/:season"
            component={Img}
            authenticated
          />
          <Redirect to="/dashboard" />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
