import React, { useEffect, useState } from "react";
import { Axios } from "../config/axios";
import {
  GET_IMAGES,
  GET_IMAGES_FILTER,
  GET_SEASONS,
  GET_FECHA_EJECUCION_JOB,
} from "../constants";
import {  Row, Col, Card, Image, Button, Nav } from "react-bootstrap";
import { useAuthDispatch, useAuthState } from "../context/auth";
import cumple from "../assets/icons/comprobar.svg";
import sinProcesar from "../assets/icons/sinProcesar.svg";

import nocumple from "../assets/icons/cerrar.svg";
import { InputLabel, Grid } from "@material-ui/core";

import moment from "moment";
import { Pagination } from "@material-ui/lab";
import Select from "react-dropdown-select";
import CampTotal from "../components/CampTotal";

export const Images = ({ history }) => {
  const [images, setImages] = useState([]);
  const [currentImages, setCurrentImages] = useState([]);
  const [backup, setBackup] = useState([]);
  const [route, setRoute] = useState(0);
  const [total, setTotal] = useState(0);
  const [fechaEjecucion, setFechaEjecucion] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  let imagesPerPage = 9
  
  const authDispatch = useAuthDispatch();
  const { user } = useAuthState();

  const [input, setInput] = useState("");
  const [codFilter, setCodFilter] = useState(-1);
  const [load, setLoad] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState(0);
  const [verdict, setVerdict] = useState("");
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    // console.log(user.regional);
    // console.log(route);
    getSeasons();
  }, []);

  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--color_primario"); //"#0075bb";
  const letterColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--color_letra"); //"#fff";

  const getSeasons = () => {
    Axios.get(GET_SEASONS)
      .then((res) => {
        // console.log(res.data.data,"seasons424");
        let season = res.data.data[0].id;
        setSeason(season);

        // let seasons = res.data.data.filter((element) => element.active === 1);
        let seasons = res.data.data;

        seasons.sort((a, b) => b.active - a.active);

        seasons = seasons.map((item) => {
          let estado = "";
          if (item.active === 1) {
            estado = "Activo";
          } else {
            estado = "inactivo";
          }
          return {
            ...item,
            id_name_active: item.id + " " + item.name + " " + estado,
          };
        });
        setSeasons(seasons);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getImages = (region, page, verdict = 0) => {
    console.log(season);
    setLoad(true);
    setPlaceholder("");
    setInput("");

    let url = `${GET_IMAGES}${season}?region=${region}&page=${page}&pageSize=${imagesPerPage}&verdict=${verdict}`;
    console.log(url);
    Axios.get(url)
      .then((res) => {
        console.log(res.data.data, "getImages");
        setCurrentImages(res.data.data.images);
        setTotal(res.data.data.total);
        setImages(res.data.data.images);
        setBackup(res.data.data.images);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };

  const handleFilter = (route, type) => {
    // setRoute(route);
    // if (type == null) {
    //   setCurrentImages(backup);
    // } else {
    //   const newImages = backup.filter((i) => i.verdict == type);
    //   setCurrentImages(newImages);
    // }

    setRoute(route);
    localStorage.setItem("verdict", route);

    if (codFilter == 0) {
      getImages(user.regional, currentPage, route);
      window.scrollTo(0, 0);
    } else {
      handleQuery(currentPage, route);
      window.scrollTo(0, 0);
    }
  };

  const logout = () => {
    authDispatch({ type: "LOGOUT" });
  };

  const handleChange = (event, value) => {
    setCurrentImages([]);
    //setRoute(0);
    setCurrentPage(value);

    if (codFilter == 0) {
      getImages(user.regional, value, route);
      window.scrollTo(0, 0);
    } else {
      handleQuery(value, route);
      window.scrollTo(0, 0);
    }
  };

  const filter = (value) => {
    console.log(value);
    console.log(codFilter);
    if (codFilter == -1 || codFilter == 0) {
      return;
    }
    setInput(value);
  };

  const onChangeCod = (c, p) => {
    setCurrentPage(1);
    setRoute(0);
    setInput("");
    setCurrentImages([]);
    setCodFilter(c);
    setPlaceholder(p);
  };

  const handleQuery = (page, verdict = 0) => {
    console.log(season);
    if (
      codFilter == -1 ||
      codFilter == 0 ||
      input.length == 0 ||
      (codFilter == 1 && input.length != 7) ||
      (codFilter == 2 && input.length != 10) ||
      (codFilter == 3 && input.length != 4)
    )
      return;
    console.log(codFilter);
    console.log(input);
    setLoad(true);
    let url = `${GET_IMAGES_FILTER}${season}?region=${user.regional}&cod=${codFilter}&query=${input}&page=${page}&pageSize=${imagesPerPage}&verdict=${verdict}`;
    console.log(url);
    Axios.get(url)
      .then((res) => {
        console.log(res.data.data);
        const data = res.data.data;
        setTotal(data.total);
        setImages(data.images);
        setBackup(data.images);
        setCurrentImages(data.images);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
  // Ejecucion job
  useEffect(() => {
    const obtenerFechaEjecucion = async () => {
      try {
        const response = await Axios.get(GET_FECHA_EJECUCION_JOB);
        const { data } = response.data;
        const fecha = moment(data.fechaEjecucion).format("YYYY-MM-DD HH:mm:ss");
        setFechaEjecucion(fecha);
      } catch (error) {
        console.error(error);
      }
    };

    obtenerFechaEjecucion();
  }, []);

  return (
    <div className="container-class">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <p className="font-negrilla">
            Último procesamiento JOB:{" "}
            <span className="font-normal">{fechaEjecucion}</span>
          </p>
          <InputLabel id="demo-simple-select-label">Misiones</InputLabel>
          <Select
            color="#85C1E9"
            options={seasons}
            labelField="id_name_active"
            className="selector"
            valueField="id"
            style={{
              borderRadius: "7px",
              height: "55px",
            }}
            placeholder="Seleccione una misión"
            onChange={(value) => {
              // console.log(value[0]);
              const selectedSeason = value[0];
              setSeason(selectedSeason.id);
              setSelected(true);
              setCodFilter(-1);
              setInput("");
              setPlaceholder("");
              setCurrentImages([]);
            }}
            values={[{ id: -1, id_name_active: "Seleccione una misión" }]}
          />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <CampTotal season={season} />
            </Grid>
          </Grid>
        </Grid>

        {selected && (
          <Grid item xs={12} md={6} lg={6}>
            <br />
            <InputLabel id="demo-simple-select-label">Filtro</InputLabel>
            <div className="filtro">
              <div>
                <div className="col-busqueda">
                  <input
                    disabled={codFilter === 0 || codFilter === -1 ? true : false}
                    className="form-control"
                    type="text"
                    placeholder={placeholder}
                    onChange={(e) => filter(e.target.value)}
                    value={input}
                  />
                  <button
                    type="submit"
                    style={{
                      background: primaryColor,
                      color: letterColor,
                      borderColor: primaryColor,
                    }}
                    className="btn btn-primary"
                    onClick={() => handleQuery(1)}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="m-3">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="inlineRadio1"
                    checked={codFilter === 0}
                    onClick={() => {
                      setCurrentImages([]);
                      setCodFilter(0);
                      getImages(user.regional, 1);
                    }}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    TODOS
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="inlineRadio2"
                    checked={codFilter === 1}
                    onClick={() => onChangeCod(1, "CEDI+CODIGORDV")}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    RDV
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="inlineRadio3"
                    checked={codFilter === 2}
                    onClick={() => onChangeCod(2, "CEDI+CODIGOCLIENTE")}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    CLIENTE
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="inlineRadio4"
                    checked={codFilter === 3}
                    onClick={() => onChangeCod(3, "CODIGOPLANTA")}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio4">
                    CEDI
                  </label>
                </div>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      {currentImages.length > 0 && (
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link
              className={route === 0 ? "bg-primario h5" : "bg-blanco h5"}
              onClick={() => handleFilter(0, null)}
            >
              Todos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={route === 1 ? "bg-primario h5" : "bg-blanco h5"}
              onClick={() => handleFilter(1, true)}
            >
              Cumple
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={route === 2 ? "bg-primario h5" : "bg-blanco h5"}
              onClick={() => handleFilter(2, false)}
            >
              No Cumple
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
              <Nav.Link
                className="bg-white text-primary h4"
                onClick={() => (window.location.href = "/Dashboard")}
              >
                Dashboard
              </Nav.Link>
            </Nav.Item> */}
        </Nav>
      )}
      {load && (
        <div className="d-flex justify-content-center">
          <div
            className="spinner-border spinner-border-lg"
            role="status"
            style={{ color: primaryColor }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {currentImages.length > 0 && (
        <>
          <Row>
            {currentImages.map((img, i) => (
              <Col key={i} sm={4} className="p-2">
                <Card className="p-3 card-imagen">
                  <div className="contenedor-imagen">
                    <Image
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(`/${img.id}/${season}`, "_blank")
                      }
                      //src={img.url}
                      src={"data:image/png;base64,"+img.imageByte}
                      fluid
                    />
                    {/* <img
                      className="imagen-respuesta"
                      width="30"
                      height="30"
                      src={img.verdict ? cumple : nocumple}
                    /> */}
                    <img
                      className="imagen-respuesta"
                      width = "30"
                      height = "30"
                      src={
                        img.verdict === null && img.processed === 0
                          ? sinProcesar
                          : img.verdict
                          ? cumple
                          : nocumple
                      }
                      alt=""
                    />
                  </div>
                  <Card.Body className="d-flex flex-column justify-content-center">
                    <Row>
                      <p className="font-negrilla">
                        <span
                          className={
                            "font-normal" +
                            (img.verdict === null && img.processed === 0
                              ? "-sinprocess"
                              : img.verdict
                              ? "-verdadero"
                              : "-falso")
                          }
                        >
                          {img.verdict === null && img.processed === 0
                            ? "Sin procesar"
                            : img.verdict
                            ? "Cumple"
                            : "No Cumple"}
                        </span>
                      </p>
                    </Row>
                    <Row>
                      <p className="font-negrilla">
                        Hora Captura:{" "}
                        <span className="font-normal">
                          {moment(img.date).format("h:mm a")}
                        </span>
                      </p>
                    </Row>
                    <Row>
                      <p className="font-negrilla">
                        Fecha Captura:{" "}
                        <span className="font-normal">
                          {moment(img.date).format("D/MM/YYYY")}
                        </span>
                      </p>
                    </Row>
                    <Row>
                      <p className="font-negrilla">
                        Procesado Por IA:{" "}
                        <span className={"font-normal"}>
                          {img.processed ? "Procesado" : "Sin Procesar"}
                        </span>
                      </p>
                    </Row>
                    <Row>
                      <p className="font-negrilla">
                        Hora Procesada IA:{" "}
                        <span className="font-normal">
                          {img.dataProcess != null
                            ? moment(img.dataProcess).format("h:mm a")
                            : ""}
                        </span>
                      </p>
                    </Row>
                    <Row>
                      <p className="font-negrilla">
                        Fecha Procesada IA:{" "}
                        <span className="font-normal">
                          {img.dataProcess != null
                            ? moment(img.dataProcess).format("D/MM/YYYY")
                            : ""}
                        </span>
                      </p>
                    </Row>
                    <Row>
                      <p className="font-negrilla">
                        Vendedor:{" "}
                        <span className="font-normal">{img.vendorCode}</span>
                      </p>
                    </Row>
                    <Row>
                      <p className="font-negrilla">
                        Regional:{" "}
                        <span className="font-normal">{img.wareHouse}</span>
                      </p>
                    </Row>
                    <Row>
                      <p className="font-negrilla">
                        Nombre Regional:{" "}
                        <span className="font-normal">{img.cediName}</span>
                      </p>
                    </Row>
                    <Row>
                      <p className="font-negrilla">
                        Codigo Cliente:{" "}
                        <span className="font-normal">{img.clientId}</span>
                      </p>
                    </Row>
                    <Row>
                      <p className="font-negrilla">
                        Nombre Cliente:{" "}
                        <span className="font-normal">{img.storeName}</span>
                      </p>
                    </Row>
                    {/* <Row>
                        <p className="h6 font-weight-bold">
                          Arbol:{" "}
                          <span className="ml-2 font-weight-normal">
                            {img.arbol === null
                              ? "SIN VALIDAR"
                              : img.arbol
                              ? "Verdadero"
                              : "Falso"}
                          </span>
                        </p>
                      </Row> */}
                  </Card.Body>
                  <Card.Footer style={{ padding: ".75rem 0.25rem" }}>
                    <Button
                      variant="primary"
                      className="detalleCard"
                      style={{
                        background: primaryColor,
                        borderColor: primaryColor,
                        color: letterColor,
                        width: "100%",
                      }}
                      onClick={() =>
                        window.open(`/${img.id}/${season}`, "_blank")
                      }
                    >
                      Detalle
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
          <div className="d-flex justify-content-center mt-5">
            <Pagination
              siblingCount={2}
              size="large"
              variant="outlined"
              color="primary"
              count={Math.ceil(total / imagesPerPage)}
              page={currentPage}
              onChange={handleChange}
            />
          </div>
        </>
      )}
    </div>
  );
};
