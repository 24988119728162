import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { GET_INFO } from "../../constants";
import { Axios } from "../../config/axios";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Info = () => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);

  const getInfo = () => {
    Axios.get(GET_INFO)
      .then((res) => {
        console.log(res.data);
        setRows(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div className="mt-4">
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Regional</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Codigo Planta</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Nombre Planta</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Total Dia</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Total Mes</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Foto Valida Dia</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Foto Valida Mes</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Foto No Valida Dia</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Foto No Valida Mes</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Foto Sin Procesar Dia</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Foto Sin Procesar Mes</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Registro Sin Foto Dia</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Registro Sin Foto Mes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.regional}
                </TableCell>
                <TableCell align="right">{row.warehouse}</TableCell>
                <TableCell align="right">{row.NombrePlanta}</TableCell>
                <TableCell align="right">{row.Totaldia}</TableCell>
                <TableCell align="right">{row.TotalMes}</TableCell>
                <TableCell align="right">{row.FotoValidaDia}</TableCell>
                <TableCell align="right">{row.FotoValidaMes}</TableCell>
                <TableCell align="right">{row.FotoNoValidaDia}</TableCell>
                <TableCell align="right">{row.FotoNoValidaMes}</TableCell>
                <TableCell align="right">{row.FotoSinProcesarDia}</TableCell>
                <TableCell align="right">{row.FotoSinProcesarMes}</TableCell>
                <TableCell align="right">{row.RegistroSinFotoDia}</TableCell>
                <TableCell align="right">{row.RegistroSinFotoMes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Info;
