import { CircularProgress, Grid } from "@material-ui/core";
import { Movie } from "@material-ui/icons";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { GET_SEASONSMOVIL, URL_BASE } from "../constants";

const TablaRDV = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [seasonVendedor, setSeasonVendedor] = useState([]);
  const [codigoVendedor, setCodigoVendedor] = useState(null);
  const [planta, setPlamtas] = useState(null);
  const [cliente_id, setCliente_id] = useState(null);
  const location = useLocation();

  const getSeasons = async () => {
    const parts = location.pathname.split("/");
    const dato = parts[2]; // Obtener el dato después del segundo slash

    try {
      console.log(dato, "response");
      setCodigoVendedor(dato);
      setPlamtas(parts[3]);
      setCliente_id(parts[4]);
      setLoader(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(userAgent);
    setLoader(true);

    if (mobile === false) {
      history.push("/dashboard");
    } else {
      getSeasons();
    }
  }, []);
  useEffect(() => {
    if (planta !== null && cliente_id !== null) {
      const fetchData = async () => {
        try {
          const response = await Axios.get(
            `${URL_BASE}${GET_SEASONSMOVIL}${codigoVendedor}/${planta}/${cliente_id}`
            // `${URL_BASE}${GET_SEASONSMOVIL}${codigoVendedor}/${planta}/${cliente_id}`
          );
          console.log(response, "response");
          setSeasonVendedor(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [planta, cliente_id, codigoVendedor]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={12}>
        {loader ? (
          <Table striped>
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>id</th>
                <th style={{ textAlign: "left" }}>Misión</th>
                <th style={{ textAlign: "left" }}>En curso / Terminada</th>
              </tr>
            </thead>
            <tbody>
              {seasonVendedor?.map((season) => (
                <tr key={season.id}>
                  <td>{season.id}</td>
                  <td>{season.name}</td>
                  <td>{season.active === 1 ? "activa" : "inactiva"}</td>
                  <td>
                    <Button
                      variant="primary"
                      className=""
                      onClick={() =>
                        //imagenesCampana/:cliente_id/:vendorcode/:planta/:seasonid/
                        history.push(
                          `/misiones/${cliente_id}/${codigoVendedor}/${planta}/${season.id}/`
                        )
                      }
                    >
                      Ver detalle
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <CircularProgress disableShrink />
        )}
      </Grid>
    </Grid>
  );
};

export default TablaRDV;
