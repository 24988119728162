import React, { useState } from "react";
import { useAuthDispatch } from "../../context/auth";
import { Axios } from "../../config/axios";

import "./login.css";
import { LOGIN } from "../../constants";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useAuthDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    Axios.post(LOGIN, {
      username,
      password,
    })
      .then((res) => {
        dispatch({ type: "LOGIN", payload: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="bg">
      <div className="container my-auto">
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto my-auto">
            <div className="card card-signin my-5">
              <div className="card-body">
                <h5 className="card-title text-center">Iniciar Sesión</h5>
                <form onSubmit={handleSubmit} className="form-signin">
                  <div className="form-label-group">
                    <input
                      type="text"
                      id="inputEmail"
                      className="form-control"
                      placeholder="Usuario"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                      autofocus
                    />
                    <label htmlFor="inputEmail">Usuario</label>
                  </div>
                  <div className="form-label-group">
                    <input
                      type="password"
                      id="inputPassword"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <label htmlFor="inputPassword">Contraseña</label>
                  </div>
                  <button
                    className="btn btn-lg btn-primary btn-block text-uppercase"
                    type="submit"
                  >
                    Ingresar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
