import React, { useEffect, useRef, useState } from "react";
import { Axios } from "../config/axios";
import {
  GET_IMAGE,
  GET_COORDINATES,
  UPDATE_VERDICT,
  UPDATE_EXPLANATION,
  GET_SEASON_BY_ID,
} from "../constants";
import { Container, Row, Col, Card, Button, Table } from "react-bootstrap";
import toasted from "toasted-notes";
import moment from "moment";
import { useAuthState } from "../context/auth";
import { Box, Grid, Modal, Typography } from "@material-ui/core";

import '../assets/css/img.css';

export const Img = (props) => {
  const id = props.match.params.id;
  const season = props.match.params.season;
  const [image, setImage] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const canvasRef = useRef(null);
  const imgRef = useRef(null);
  const [update, setUpdate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [explanation, setExplanation] = useState("");
  const [newList, setNewList] = useState([]);
  const [cs, setCs] = useState([]);
  const [backup, setBackup] = useState([]);
  const [urlImage, setUrlImage] = useState("");
  const [mision, setMision] = useState("");
  const { user } = useAuthState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    width: 215,
    bgcolor: "background.paper",
    border: "1px solid #fff",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };
  //test
  const getImage = () => {
    Axios.get(GET_IMAGE + id)
      .then((res) => {
        setImage(res.data.data);
        const img = res.data.data;
        const cs = img.coordinates;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        //setUrlImage(img.url);
        setUrlImage("data:image/png;base64,"+img.imageByte)
        var imgPath = "data:image/png;base64,"+img.imageByte //img.url;
        var image = new Image();
        image.src = imgPath;
        let mydict = {};

        cs.forEach((c) => {
          if (typeof mydict[c.className] === "undefined")
            mydict[c.className] =
              "#" + Math.floor(Math.random() * 16777215).toString(16);
        });

        setCs(cs);
        setBackup(cs);

        image.onload = function () {
          ctx.drawImage(image, 0, 0);
          cs.forEach((c) => {
            const ymin = c.ymin;
            const ymax = c.ymax;
            const xmin = c.xmin;
            const xmax = c.xmax;
            ctx.beginPath();
            ctx.lineWidth = "5";
            ctx.strokeStyle = mydict[c.className];
            ctx.rect(xmin, ymin, xmax - xmin, ymax - ymin);
            //ctx.setLineDash([2, 5, 5, 15]);
            ctx.fillStyle = mydict[c.className];
            ctx.font = "bold 10pt sans-serif";
            ctx.fillText(c.className, xmin, ymin - 2);
            ctx.stroke();
          });
        };
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteObject = (c) => {
    let newCs = cs;
    newCs = newCs.filter((x) => x.className != c.id);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let image = new Image();
    image.src = urlImage;

    let mydict = {};

    newCs.forEach((c) => {
      if (typeof mydict[c.className] === "undefined")
        mydict[c.className] =
          "#" + Math.floor(Math.random() * 16777215).toString(16);
    });

    image.onload = function () {
      ctx.drawImage(image, 0, 0);
      newCs.forEach((c) => {
        const ymin = c.ymin;
        const ymax = c.ymax;
        const xmin = c.xmin;
        const xmax = c.xmax;
        ctx.beginPath();
        ctx.lineWidth = "5";
        ctx.strokeStyle = mydict[c.className];
        ctx.rect(xmin, ymin, xmax - xmin, ymax - ymin);
        //ctx.setLineDash([2, 5, 5, 15]);
        ctx.fillStyle = mydict[c.className];
        ctx.font = "bold 10pt sans-serif";
        ctx.fillText(c.className, xmin, ymin - 2);
        ctx.stroke();
      });
    };
    setCs([...newCs]);
  };

  const drawObject = (c) => {
    let newCs = cs;
    let recovery = backup.filter((x) => x.className == c.id);
    recovery.forEach((x) => {
      newCs.push(x);
    });
    newCs.push();
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let image = new Image();
    image.src = urlImage;

    let mydict = {};

    newCs.forEach((c) => {
      if (typeof mydict[c.className] === "undefined")
        mydict[c.className] =
          "#" + Math.floor(Math.random() * 16777215).toString(16);
    });

    image.onload = function () {
      ctx.drawImage(image, 0, 0);
      newCs.forEach((c) => {
        const ymin = c.ymin;
        const ymax = c.ymax;
        const xmin = c.xmin;
        const xmax = c.xmax;
        ctx.beginPath();
        ctx.lineWidth = "5";
        ctx.strokeStyle = mydict[c.className];
        ctx.rect(xmin, ymin, xmax - xmin, ymax - ymin);
        //ctx.setLineDash([2, 5, 5, 15]);
        ctx.fillStyle = mydict[c.className];
        ctx.font = "bold 10pt sans-serif";
        ctx.fillText(c.className, xmin, ymin - 2);
        ctx.stroke();
      });
    };
    setCs([...newCs]);
  };

  const getCoordinates = () => {
    Axios.get(GET_COORDINATES + id)
      .then((res) => {
        let data = res.data.data;
        // console.log(res,"coordenadas")
        const newlist = data.filter(
          (x) => x.id == "TAPAS" || x.id == "PRECIOS" || x.id == "ARBOL"
        );
        setNewList(newlist);

        //data = data.map(x => x.id != "TAPAS" && x.id != "PRECIOS" ? x : null)
        data = data.filter(
          (x) => x.id != "TAPAS" && x.id != "PRECIOS" && x.id != "ARBOL"
        );

        data = data.map((d) => {
          return {
            ...d,
            select: true,
          };
        });
        setCoordinates(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getImage();
    getCoordinates();
  }, [update]);

  const updateVerdict = () => {
    const data = {
      IdImage: image.id,
      IdUser: user.id,
      Update: image.verdict ? false : true,
    };
    Axios.put(`${UPDATE_VERDICT}${image.id}`, data)
      .then((res) => {
        toasted.notify(res.data.message);
        setUpdate(!update);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        handleClose();
      });
  };

  const updateExplanation = () => {
    Axios.put(UPDATE_EXPLANATION, {
      id: image.id,
      explanation,
    })
      .then((res) => {
        toasted.notify(res.data.message);
        setUpdate(!update);
        setEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSeasons = async () => {
    await Axios.get(`${GET_SEASON_BY_ID}?idSeason=${season}`)
      .then((res) => {
        setMision(res.data.data.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSeasons();
  }, [image]);

  const handleTotal = () => {
    let total = 0;
    coordinates.forEach((x) => {
      total += x.count;
    });

    return total;
  };

  return (
    <div className="contenedor-detalle" style={{ padding: "1.5rem" }}>
      <Card className="p-3" style={{ marginBottom: "10px" }}>
        <Card.Header>
          <h4>Información del Cliente</h4>
        </Card.Header>
        <Card.Body className="d-flex flex-column justify-content-center">
          <Row>
            <p className="font-negrilla">
              Mision: <span className="font-normal">{mision}</span>
            </p>
          </Row>
          {/* <Row>
            <p className="font-negrilla">
              Veredicto:{" "}
              <span
                className={
                  "font-normal" + (image.verdict ? "-verdadero" : "-falso")
                }
              >
                {image.verdict ? "Cumple" : "No Cumple"}
              </span>
            </p>
          </Row> */}
          <Row>
            <p className="font-negrilla">
              <span
                className={
                  "font-normal" +
                  (image.verdict === null && image.processed === 0
                    ? "-sinprocess"
                    : image.verdict
                    ? "-verdadero"
                    : "-falso")
                }
              >
                {image.verdict === null && image.processed === 0
                  ? "Sin procesar"
                  : image.verdict
                  ? "Cumple"
                  : "No Cumple"}
              </span>
            </p>
          </Row>

          <Row>
            <p className="font-negrilla">
              Hora Captura:{" "}
              <span className="font-normal">
                {moment(image.date).format("h:mm a")}
              </span>
            </p>
          </Row>
          <Row>
            <p className="font-negrilla">
              Fecha Captura:{" "}
              <span className="font-normal">
                {moment(image.date).format("D/MM/YYYY")}
              </span>
            </p>
          </Row>
          <Row>
            <p className="font-negrilla">
              Procesado por IA:{" "}
              <span className="font-normal">
                {image.processed ? "Procesado" : "Sin Procesar"}
              </span>
            </p>
          </Row>
          <Row>
            <p className="font-negrilla">
              Hora Procesada IA:{" "}
              <span className="font-normal">
                {image.dataProcess != null
                  ? moment(image.dataProcess).format("h:mm a")
                  : ""}
              </span>
            </p>
          </Row>
          <Row>
            <p className="font-negrilla">
              Fecha Procesada IA:{" "}
              <span className="font-normal">
                {image.dataProcess != null
                  ? moment(image.dataProcess).format("D/MM/YYYY")
                  : ""}
              </span>
            </p>
          </Row>
          <Row>
            <p className="font-negrilla">
              Vendedor: <span className="font-normal">{image.vendorCode}</span>
            </p>
          </Row>
          <Row>
            <p className="font-negrilla">
              Regional: <span className="font-normal">{image.wareHouse}</span>
            </p>
          </Row>
          <Row>
            <p className="font-negrilla">
              Nombre Regional:{" "}
              <span className="font-normal">{image.cediName}</span>
            </p>
          </Row>
          <Row>
            <p className="font-negrilla">
              Codigo Cliente:{" "}
              <span className="font-normal">{image.storeCode}</span>
            </p>
          </Row>
          <Row>
            <p className="font-negrilla">
              Nombre Cliente:{" "}
              <span className="font-normal">{image.storeName}</span>
            </p>
          </Row>
        </Card.Body>
      </Card>
{/*  */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Producto</th>
                <th>Cantidad</th>
                <th>Porcentaje</th>
                <th>Seleccionar</th>
              </tr>
            </thead>
            <tbody>
              {coordinates.map((c, i) => (
                <tr key={i}>
                  <td className="text-center">{i + 1}</td>
                  <td className="text-center">{c.id}</td>
                  <td className="text-center">{c.count}</td>
                  <td className="text-center">
                    {((c.count * 100) / handleTotal()).toFixed(1) + " %"}
                  </td>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={c.select}
                      onClick={(e) => {
                        let change = e.target.checked;
                        coordinates.map((co) => {
                          if (co.id == c.id) {
                            co.select = e.target.checked;
                          }
                          return co;
                        });
                        setCoordinates([...coordinates]);
                        if (!change) {
                          deleteObject(c);
                        } else {
                          drawObject(c);
                        }
                      }}
                      data-toggle="toggle"
                      data-size="sm"
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td className="text-center">Total</td>
                <td className="text-center">{handleTotal()}</td>
                <td className="text-center">{"100%"}</td>
              </tr>
            </tbody>
          </Table>

          <Table responsive bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Elemento</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {newList.map((c, i) => (
                <tr key={i}>
                  <td className="text-center">{i + 1}</td>
                  <td className="text-center">{c.id}</td>
                  <td className="text-center">{c.count}</td>
                </tr>
              ))}
              {/* <tr>
                <td></td>
                <td className="text-center">Total</td>
                <td className="text-center">{newList && handleTotal()}</td>
              </tr> */}
            </tbody>
          </Table>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <div class="scroll_horizontal">
              {/**
            <Image
            style={{ cursor: "pointer" }}
            src={image.url}
            fluid
            /> 
            **/}

            <div className="canvas-container">
                <canvas
                  id="myCanvas"
                  width="1024"
                  height="1024"
                  style={{ border: "1px solid #d3d3d3" }}
                  ref={canvasRef}
                  {...props}
                />
              </div>
              {/***/}
          </div>
        </Grid>
      </Grid>
      {/*  */}
      <Grid container spacing={3} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} md={6} lg={6}>
          <Card className="p-3 card-imagen">
            <div className="row">
              <div className="col-sm">
                <p>
                  <h4>Veredicto</h4>
                  <span
                    className={
                      "font-grande" + (image.verdict ? "-verdadero" : "-falso")
                    }
                  >
                    {image.verdict ? "Cumple" : "No Cumple"}
                  </span>
                </p>
              </div>
              <div className="col-sm">
                <button onClick={handleOpen} className="btn btn-primary">
                  Cambiar Veredicto
                </button>
              </div>
              {/* <div>
                <p className="font-negrilla">Log Modificación:</p>
                <p className="font-negrilla">
                  Fecha modificacion:{" "}
                  <span className="font-normal">{image.dataUser}</span>
                </p>
                <p className="font-negrilla">
                  Usuario:{" "}
                  <span className="font-normal">{image.updataUser}</span>
                </p>
              </div> */}
            </div>
          </Card>
        </Grid>
        
        <Grid item xs={12} md={6} lg={6}>
          <Card className="p-3 card-imagen">
            <div className="row">
              <div className="col-sm-9">
                <p className="font-negrilla">Log Modificación:</p>
                <p className="font-negrilla">
                  Fecha modificacion:{" "}
                  <span className="font-normal">{image.dataUser}</span>
                </p>
                <p className="font-negrilla">
                  Usuario:{" "}
                  <span className="font-normal">{image.updataUser}</span>
                </p>
              </div>
            </div>
          </Card>
        </Grid>
        
        <Grid item xs={12} md={6} lg={6}>
          <Card className="p-3 card-imagen">
            <div className="row">
              <div className="col-sm-9">
                {edit ? (
                  <>
                    <h4>Explicación</h4>
                    <div class="form-group">
                      <textarea
                        class="form-control rounded-0"
                        id="exampleFormControlTextarea1"
                        rows="10"
                        defaultValue={explanation}
                        onChange={(e) => setExplanation(e.target.value)}
                      ></textarea>
                    </div>
                  </>
                ) : (
                  <p>
                    {" "}
                    <h4>Explicación</h4>
                    {image.explanation}
                  </p>
                )}
              </div>
              <div className="col-sm-3">
                {edit ? (
                  <>
                    <button
                      onClick={updateExplanation}
                      className="btn btn-primary mb-3"
                    >
                      Guardar
                    </button>
                    <button
                      onClick={() => {
                        setEdit(false);
                      }}
                      className="btn btn-primary"
                    >
                      Cancelar
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => {
                      setEdit(true);
                      setExplanation(image.explanation);
                    }}
                    className="btn btn-primary"
                  >
                    Editar
                  </button>
                )}
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
      {/* <p>
            <h4>Arbol:</h4>{" "}
            {image.arbol === null
              ? "SIN VALIDAR"
              : image.arbol
              ? "Verdadero"
              : "Falso"}
          </p> */}
      {/* <p>
            <h4>Filas:</h4> {image.filas}
          </p> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ¿Estas seguro de cambiar el veredicto?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="btn-modal">
              <button onClick={updateVerdict} className="btn btn-primary">
                Si
              </button>
              <button onClick={handleClose} className="btn btn-primary">
                No
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};