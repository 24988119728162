import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  LinearProgress,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {
  GET_REPORTE_EXCEL,
  GET_REPORTE_REGION_EXCEL,
  GET_REPORTE_PLANTA_EXCEL,
  GET_SEASONS,
  OBJETIVES,
  OBJETIVES_DETAIL,
  URL_BASE,
  GET_TRACING_EXCEL,
} from "../../../constants";
import { Axios } from "../../../config/axios";

import ReactExport from "react-export-excel";
import moment from "moment";
import Select from "react-dropdown-select";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row, handleRegion, handlePlanta, setHoja } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setHoja(row.region);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.region}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat("en").format(row.objetivo)}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat("en").format(row.objetivoAcumulado.toFixed(0))}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat("en").format(row.ejecutados)}
        </TableCell>

        <TableCell>
          {new Intl.NumberFormat("en").format(
            row.cumplimiento && row.cumplimiento.toFixed(1)
          ) + "%"}
        </TableCell>

        {/* <TableCell>
          <CloudDownloadIcon
            color="primary"
            onClick={() => handleRegion(row.region)}
            style={{ cursor: "pointer" }}
          />
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="h6"
                gutterBottom
                component="div"
              >
                Sedes
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Planta
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Objetivo
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Objetivo Acumulado
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Ejecutados
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Cumplimiento
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.detail &&
                    row.detail.map((d, i) => (
                      <TableRow key={i}>
                        <TableCell align="left">{d.nombrePlanta}</TableCell>
                        <TableCell align="left">
                          {new Intl.NumberFormat("en").format(d.objetivo)}
                        </TableCell>
                        <TableCell align="left">
                          {new Intl.NumberFormat("en").format(
                            d.objetivoAcumulado.toFixed(0)
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {new Intl.NumberFormat("en").format(d.ejecutados)}
                        </TableCell>
                        <TableCell>
                          {new Intl.NumberFormat("en").format(
                            d.cumplimiento && d.cumplimiento.toFixed(1)
                          ) + "%"}
                        </TableCell>
                        {/* <TableCell>
                          <CloudDownloadIcon
                            color="primary"
                            onClick={() => handlePlanta(d)}
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function LinearProgressWithLabel(props) {
  console.log(props);
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

//Contiene la celda con los Totales de objetivo, ejecutado,... etc
function RowTotales(props) {
  const { totalObjetivo, totalObjetivoAcumulado, totalEjecutados, totalCumplimiento, setTotales, row } = props;
  setTotales(row)
  return(<TableRow>
    <TableCell> </TableCell>
      <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
      <TableCell align="left">{new Intl.NumberFormat("en").format(
        totalObjetivo.toFixed(0))}</TableCell>
      <TableCell align="left">{new Intl.NumberFormat("en").format(
        totalObjetivoAcumulado.toFixed(0))}</TableCell>
      <TableCell align="left">{new Intl.NumberFormat("en").format(
        totalEjecutados.toFixed(0))}</TableCell>
      <TableCell align="left">{new Intl.NumberFormat("en").format(
        totalCumplimiento.toFixed(1)
            ) + "%"}</TableCell>
    </TableRow>
  );
}

const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--color_primario');
const letterColor = getComputedStyle(document.documentElement).getPropertyValue('--color_letra');//"#fff";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: letterColor,
  },
  button: {
    color: letterColor,
    background: primaryColor,
    textTransform: "none",
  }
}));

export const Goals = () => {

  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [goals, setGoals] = useState([]);
  const [excel, setExcel] = useState([]);
  const [nacional, setNacional] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState();
  const [hoja, setHoja] = useState();
  const [dow, setDow] = useState(false);
  const [selected, setSelected] = useState(false);
  const [totalObjetivo, setTotalObjetivo] = useState(0);
  const [totalEjecutados, setTotalEjecutados] = useState(0);
  const [totalObjetivoAcumulado, setTotalObjetivoAcumulado] = useState(0);
  const [totalCumplimiento, setTotalCumplimiento] = useState(0);

  const salesForce = [
    {
      label: "BNA",
      value: "BNA",
    },
    {
      label: "FVI",
      value: "FVI",
    },
  ];

  const [force, setForce] = useState(salesForce[0].value);

  const getSeasons = () => {
    Axios.get(GET_SEASONS)
      .then((res) => {
        // console.log("Temporadas goals :", res.data.data);
        // console.log("Temporadas Activas :", res.data.data.filter(temp => temp.active == 1));
        let season = res.data.data[0].id;
        setSeason(res.data.data[0]);
        setSeasons(res.data.data.filter(temp => temp.active == 1));
        //getObjetives(season);
        //getReporteExcel(season);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getObjetives = (season) => {
    setLoading(true);
    console.log("Consulta objetivos-> ", OBJETIVES + season);
    Axios.get(OBJETIVES + season)
      .then((res) => {
        console.log("Consulta resultados -> ", res.data);
        console.log(res.data);
        let data = res.data;
        Axios.get(OBJETIVES_DETAIL + season)
          .then((res) => {
            console.log("details ", res.data);
            const details = res.data;
            groupby(data, details);
            setGoals(data);
            console.log(goals)
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReporteExcel = (season) => {
    setDow(true);
    Axios.get(GET_REPORTE_EXCEL + season)
      .then((res) => {
        console.log(res.data);
        setNacional(res.data);
        setDow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getExcelMision = (season) => {
      try {
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = URL_BASE + GET_TRACING_EXCEL + season;
        a.click();
      }
      catch (e) {
      alert(e);
      }
    }


  //objetivo, ejecutados, objetivoAcumulado, cumplimiento
  const getTotalObjetivo = (tabla) => {
    tabla && 
    setTotalObjetivo(tabla.map(({ objetivo }) => objetivo).reduce((sum, i) => sum + i, 0))
  }
  const getTotalEjecutados = (tabla) => {
    tabla &&
    setTotalEjecutados(tabla.map(({ ejecutados }) => ejecutados).reduce((sum, i) => sum + i, 0));
  }
  const getTotalObjetuvoAcumulado = (tabla) => {
    tabla &&
    setTotalObjetivoAcumulado(tabla.map(({ objetivoAcumulado }) => objetivoAcumulado).reduce((sum, i) => sum + i, 0));
  }
  const getTotalCumplimiento = () => {
    totalObjetivoAcumulado != 0 ? (
    setTotalCumplimiento((totalEjecutados /totalObjetivoAcumulado)*100) )
    : setTotalCumplimiento(0);
  }

  const setTotales = (tabla) =>{
    getTotalObjetivo(tabla);
    getTotalEjecutados(tabla)
    getTotalObjetuvoAcumulado(tabla)
    getTotalCumplimiento();
  }


  const groupby = (data, details) => {
    data.forEach((d) => {
      details.forEach((de) => {
        if (d.region == de.region) {
          let detail = d.detail == null ? [] : d.detail;
          detail.push(de);
          d.detail = detail;
        }
      });
    });
  };

  const handleRegion = (region) => {
    setHoja(region);
    getReporteRegionExcel(season.id, region);
  };

  const handlePlanta = (planta) => {
    getReportePlantaExcel(season.id, planta.cod, hoja + " - " + planta.planta);
  };

  const getReporteRegionExcel = (season, region) => {
    let url = URL_BASE + GET_REPORTE_REGION_EXCEL + season + "/" + region;
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    a.click();
  };

  const getReportePlantaExcel = (season, planta, hoja) => {
    let url =
      URL_BASE + GET_REPORTE_PLANTA_EXCEL + season + "/" + planta + "/" + hoja;
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    a.click();
  };

  useEffect(() => {
    getSeasons();
    //getObjetives();
    //getReporteExcel();
  }, []);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3} style={{ minHeight : "30rem", }}>
        {/* <Grid item xs={12} md={6} lg={3}>
          <InputLabel id="demo-simple-select-label">
            Fuerza de ventas
          </InputLabel>
          <Select
            style={{
              marginTop: "1rem",
              borderRadius: "7px",
              height: "55px",
            }}
            color="#85C1E9"
            options={salesForce}
            labelField="label"
            valueField="value"
            onChange={(value) => {
              setForce(value[0].value);
              getObjetives(season.id, value[0].value);
            }}
            values={[salesForce[0]]}
          />
        </Grid> */}
        <Grid item xs={12} md={6} lg={6}>
          <InputLabel id="demo-simple-select-label">Misiones</InputLabel>
          <Select
            style={{
              marginTop: "1rem",
              borderRadius: "7px",
              height: "55px",
            }}
            color="#85C1E9"
            options={seasons}
            labelField="name"
            valueField="id"
            values={[{ id: -1, name: "Seleccione una Misión" }]}
            onChange={(value) => {
              console.log(value[0]);
              const selectedSeason = value[0];
              setSelected(true);
              setSeason(selectedSeason);
              getObjetives(selectedSeason.id);
              setTotales(goals);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          {/* 
          <Typography variant="h6" gutterBottom>
            Regiones
          </Typography>
          
          <Button
            variant="contained"
            onClick={() => {
              let a = document.createElement("a");
              a.target = "_blank";
              a.href = URL_BASE + GET_REPORTE_EXCEL + season.id;
              a.click();
            }}
          >
            Descargar Datos
          </Button> */}
          {selected && (
            <>
            <InputLabel id="demo-simple-select-label">Regiones</InputLabel>
            <Button
              variant="contained"
              style={{ marginTop: "10px", marginLeft: "10px" }}
              className={classes.button}
              onClick={() => getExcelMision(season.id)}
            >
              Seguimiento
            </Button>
            </>
          )}
        </Grid>
        {goals.length > 0 && (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell style={{ fontWeight: "bold" }}>Region</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Objetivo</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Objetivo Acumulado
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Ejecutados</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Cumplimiento
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold" }}>Descarga</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                goals.map((row, i) => (
                  <Row
                    key={i}
                    row={row}
                    handleRegion={handleRegion}
                    handlePlanta={handlePlanta}
                    setHoja={setHoja}
                  />
                ))
              }
                {/** Total de los campos de la tabla
                objetivo, ejecutados, objetivoAcumulado, cumplimiento
                **/}
              {goals.length >0 &&
                <RowTotales
                  totalObjetivo = {totalObjetivo}
                  totalObjetivoAcumulado = {totalObjetivoAcumulado}
                  totalEjecutados = {totalEjecutados}
                  totalCumplimiento = {totalCumplimiento}
                  setTotales = {setTotales}
                  row = {goals}
                />
              }
            {/***/}
            </TableBody>
          </Table>
        </TableContainer>
        )}
      </Grid>
    </>
  );
};
