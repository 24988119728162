export const validateExcelVendor = (data, type) => {
  let valid = true;

  if(data.length == 0) return false

  data.forEach((x) => {
    if (!validateObj(x, type)) valid = false;
  });

  return valid;
};

function validateObj(x, type) {
  console.log("type ",type)
  console.log("keys ",Object.keys(x).length)
  if (type == 0 && Object.keys(x).length != 5) return false;
  else if (type == 2 && Object.keys(x).length != 2) return false;

  // for (var prop in x) {
  //   console.log(typeof x[prop]);
  //   console.log(prop);
  //   if (typeof x[prop] !== "number") {
  //     return false;
  //   }
  // }
  return true;
}
