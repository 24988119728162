import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Grid,
  Paper,
  makeStyles,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Container,
  Typography,
  InputLabel,
  Button,
  Box,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import CachedIcon from "@material-ui/icons/Cached";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import InfoIcon from "@material-ui/icons/Info";
import PanToolIcon from "@material-ui/icons/PanTool";
import DeleteIcon from "@material-ui/icons/Delete";

import Select from "react-dropdown-select";

import { Axios } from "../../../config/axios";

import * as XLSX from "xlsx";

import {
  GET_PARETO_REPORTE,
  GET_PARETO_REPORTE_EXCEL,
  GET_SEASONS,
  GET_TABLE_PARETO_EXCEL,
  GET_VENDOR,
  GET_VENDOR_EXCEL,
  GET_VENDOR_TARGET,
  GET_VENDOR_TARGET_EXCEL,
  UPLOAD_DATA_PARETO,
  UPLOAD_DATA_VENDOR,
  UPLOAD_DATA_VENDOR_TARGET,
  URL_BASE,
} from "../../../constants";
import { formatPercentaje } from "../../../utils/percentaje";
import { useAuthState } from "../../../context/auth";

import toasted from "toasted-notes";
import { validateExcel } from "../../../utils/validateExcel";
import { validateExcelVendor } from "../../../utils/validateExcelVendor";

const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--color_primario');
const letterColor = getComputedStyle(document.documentElement).getPropertyValue('--color_letra');

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  button: {
    background: primaryColor,
    color: letterColor,
    margin: theme.spacing(1),
    textTransform: "none",
  },
  container: {
    height: "200px",
  },
}));

export const Vendor = () => {
  const classes = useStyles();
  const { user } = useAuthState();

  const [data, setData] = useState([]);

  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState("");

  const [selected, setSelected] = useState(true);

  const [type, setType] = useState(0);

  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  const salesForce = [
    {
      label: "BNA",
      value: "BNA",
    },
    {
      label: "FVI",
      value: "FVI",
    },
  ];

  const [force, setForce] = useState(salesForce[0].value);

  const getSeasons = () => {
    Axios.get(GET_SEASONS)
      .then((res) => {
        console.log(res.data.data);
        let season = res.data.data[0];
        setSeason(season);
        setSeasons(res.data.data.filter(temp => temp.active == 1));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    setLoad(true);
    Axios.get(GET_VENDOR)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
        setLoad(false)
      })
      .catch((err) => {
        setLoad(false)
        console.log(err);
      });
  };

  const handleFile = (e) => {
    const target = e.target;
    const name = target.name;
    if (name === "file") {
      let reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = (e) => {
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data, { type: "array" });
        let json = XLSX.utils.sheet_to_json(workbook.Sheets["VENDOR"]);
        console.log(json);
        const msg = type == 0 ? "cargar" : "eliminar"
        Swal.fire({
          title: `¿Estas seguro de ${msg} los datos?`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: `Si`,
          cancelButtonText: "No",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            const valid = validateExcelVendor(json,type);
            valid
              ? uploadData(json)
              : toasted.notify(`Los datos no son correctos`);
            console.log(valid);
          } else if (result.isDenied) {
            return;
          }
        });
      };
    }
  };

  const uploadData = (data) => {
    setLoading(true);
    const msg = type == 0 ? "agregados" : "eliminados";
    try {
      Axios.post(UPLOAD_DATA_VENDOR, {
        type,
        data,
      })
        .then((res) => {
          console.log(res);
          toasted.notify(`Datos ${msg} correctamente`);
          getData(season.id);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toasted.notify(`Error`);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData()
  }, []);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3} >
        {/* <Grid item xs={12} sm={3} lg={3}>
          <InputLabel id="demo-simple-select-label">
            Fuerza de ventas
          </InputLabel>
          <Select
            style={{
              marginTop: "1rem",
              borderRadius: "7px",
              height: "55px",
            }}
            color="#85C1E9"
            options={salesForce}
            labelField="label"
            valueField="value"
            onChange={(value) => {
              setForce(value[0].value);
              getClients(season.id, value[0].value);
            }}
            values={[salesForce[0]]}
          />
        </Grid> */}
        {/* <Grid item xs={12} md={3} lg={3}>
          <InputLabel id="demo-simple-select-label">Misiones</InputLabel>
          <Select
            style={{
              marginTop: "1rem",
              borderRadius: "7px",
              height: "55px",
            }}
            color="#85C1E9"
            options={seasons}
            labelField="name"
            valueField="id"
            values={[{ id: -1, name: "Seleccione una Misión" }]}
            onChange={(value) => {
              console.log(value[0]);
              const selectedSeason = value[0];
              setSeason(selectedSeason);
              getData(selectedSeason.id);
              setSelected(true);
            }}
          />
        </Grid> */}

        {selected && (
          <Grid item xs={12} sm={9} lg={9}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <input
                type="file"
                name="file"
                id="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleFile}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<PanToolIcon />}
                onClick={() => {
                  let url = "/files/ManualVendor.pdf";
                  let a = document.createElement("a");
                  a.target = "_blank";
                  a.href = url;
                  a.click();
                }}
              >
                Manual
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  setType(0);
                  document.getElementById("file").click();
                }}
              >
                Agregar Datos
              </Button>
              {/* <Button
     variant="contained"
     className={classes.button}
     startIcon={<CachedIcon />}
     onClick={() => {
       setType(1);
       document.getElementById("file").click();
     }}
   >
     Reemplazar Pareto
   </Button> */}
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setType(2);
                  document.getElementById("file").click();
                }}
              >
                Eliminar Datos
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<CloudDownloadIcon />}
                onClick={() => {
                  let url = URL_BASE + GET_VENDOR_EXCEL;
                  let a = document.createElement("a");
                  a.target = "_blank";
                  a.href = url;
                  a.click();
                }}
              >
                Descargar Datos
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      {load && (
          <div className="d-flex justify-content-center">
            <div
              className="spinner-border spinner-border-lg"
              role="status"
              style={{color: primaryColor}}
            >
              <span className="sr-only" >Loading...</span>
            </div>
          </div>
        )}
      <Box m={2} />
      {data.length > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer style={{ maxHeight: 470 }}>
              <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 70 }}>Cedi</TableCell>
                    <TableCell style={{ minWidth: 70 }}>
                      Representante
                    </TableCell>
                    <TableCell style={{ minWidth: 70 }}>Salesforce</TableCell>
                    <TableCell style={{ minWidth: 70 }}>Mesa</TableCell>
                    <TableCell style={{ minWidth: 70 }}>Sala</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell>{row.warehouse}</TableCell>
                      <TableCell>{row.codRdv}</TableCell>
                      <TableCell>{row.salesforce}</TableCell>
                      <TableCell>{row.mesa}</TableCell>
                      <TableCell>{row.sala}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
};