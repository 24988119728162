export const calculate = (data) => {
  let values = [0, 0, 0];
  let total = 0;
  let tv = 0;
  let tf = 0;
  let p = 0;
  data.forEach((i) => {
    total += i.total;
    tv += i.sumV;
    tf += i.sumF;
    p += i.pareto;
  });
  values[0] = total;
  values[1] = tv;
  values[2] = tf;
  values[3] = p;
  values[4] = tv > 0 ? (p / tv) * 100 : 0;
  values[5] = (tv / total) * 100;
  return values;
};
