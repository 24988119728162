import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Button, ListGroup, Col } from "react-bootstrap";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  GET_IMAGENESMOVILVENDEDOR,
  GET_IMAGES,
  GET_TOTALESMOVIL_SEASONS,
  URL_BASE,
} from "../constants";
import {
  Box,
  CardContent,
  CardMedia,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";

const TablaRVDImage = () => {
  const location = useLocation();
  const history = useHistory();
  const [vendorcode, setVendorcode] = useState(null);
  const [cliente_id, setCliente_id] = useState(null);
  const [planta, setPlanta] = useState(null);
  const [seasonid, setSeasonid] = useState(null);
  const [images, setImages] = useState([]);
  //const [dato, setDato] = useState(null);
  const getImagesbySeason = async () => {
    const parts = location.pathname.split("/");
    const seasonid = parts[5];
    const vendorcode = parts[3];
    const planta = parts[4];  
    const cliente_id = parts[2];
    setVendorcode(vendorcode);
    setPlanta(planta);
    setSeasonid(parts[5]);
    setCliente_id(cliente_id);
    
    try {
      const response = await Axios.get(
        // `${URL_BASE}${GET_IMAGES}${dato}?region=null&page=1&pageSize=20&verdict=0`
        //`http://localhost:5000/${GET_IMAGES}${dato}?region=null&page=1&pageSize=20&verdict=0`
        //`http://localhost:5000/${GET_IMAGENESMOVILVENDEDOR}${dato}?region=null&page=1&pageSize=20&verdict=0`
        //`http://localhost:5000/${GET_IMAGENESMOVILVENDEDOR}${cliente_id}/${vendorcode}/${planta}/${dato}`
        `${URL_BASE}${GET_IMAGENESMOVILVENDEDOR}${cliente_id}/${vendorcode}/${planta}/${seasonid}`
      );
      console.log(parts, "response1");
      setImages(response.data.data.images);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const userAgent = navigator.userAgent;
    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(userAgent);

    if (mobile === false) {
      history.push("/dashboard");
    } else {
      getImagesbySeason();
    }
  }, []);
  return (
    <>
      {images?.map((image, i) => (
        <Col key={i} sm={4} className="p-2">
          <Card className="p-3 card-imagen">
            <div className="contenedor-imagen">
              <Card.Img variant="top" src={image.url} />
              <ListGroup variant="flush">
                <p className="font-negrilla">
                  Fecha Captura:{" "}
                  <span className="font-normal">
                    {new Date(image.date).toLocaleDateString("es-ES", {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    })}
                  </span>
                </p>
                <p className="font-negrilla">
                  Estado imagen:{" "}
                  <span className={"font-normal"}>
                    {image.verdict === true
                      ? "Cumple"
                      : image.verdict === false
                      ? "No cumple"
                      : "Sin procesar"}
                  </span>
                </p>
                <p className="font-negrilla">
                  id: <span className="font-normal">{image.id}</span>
                </p>
              </ListGroup>
            </div>
          </Card>
        </Col>
      ))}

      <Box mt={2} display="flex" justifyContent="center">
        <Button
          variant="primary"
          onClick={() =>
            history.goBack()
          }
        >
          Volver
        </Button>
      </Box>
    </>
  );
};

export default TablaRVDImage;
