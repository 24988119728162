import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { Table } from "react-bootstrap";
import Axios from "axios";
import { GET_REPORTES_TOTALES_WEB_SEASON, URL_BASE } from "../constants";

// `GET_REPORTES_TOTATLES_WEB_SEASON${season}`
const CampTotal = ({ season }) => {
  // const classes = useStyles();
  const [totalImages, setTotalImages] = useState([]);
  const [imagenesAprobadas, setImagenesAprobadas] = useState([]);
  const [imagenesRechazadas, setImagenesRechazadas] = useState([]);
  const [procesadaManual, setProcesadaManual] = useState([]);
  const [totalAnalizadas, setTotalAnalizadas] = useState([]);
  const [sinProcesar, setSinProcesar] = useState([]);

  const [loader, setLoader] = useState(true);

  const fetchSeasonMovil = async (seasonAdmin) => {
    try {
      const response = await Axios.get(
        `${URL_BASE}${GET_REPORTES_TOTALES_WEB_SEASON}${seasonAdmin}`
      );
      setTotalImages(response.data.data.totalCampana);
      setImagenesAprobadas(response.data.data.cumplenCampanaCount);
      setImagenesRechazadas(response.data.data.noCumplenCampanaCount);
      setTotalAnalizadas(response.data.data.procesadasPorIACount);
      setProcesadaManual(response.data.data.procesadasManualCount); 
      setSinProcesar(response.data.data.sinProcesarCount);
      setLoader(true);
      // console.log(response.data, "season");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (season != 0 || season != null || season != undefined) {
      setLoader(false);
      fetchSeasonMovil(season);
    }
  }, [season]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={12}>
        {loader ? (
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Total Imagenes</th>
                <th>Sin procesar</th>
                <th>Imagenes aprobadas</th>
                <th>Imagenes rechazadas</th>
                <th>Cambio veredicto manual</th>
                <th>Total analizadas</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{totalImages}</td>
                <td>{sinProcesar}</td>
                <td>{imagenesAprobadas}</td>
                <td>{imagenesRechazadas}</td>
                <td>{procesadaManual}</td>
                <td>{totalAnalizadas}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <CircularProgress disableShrink />
        )}
      </Grid>
    </Grid>
  );
};

export default CampTotal;
